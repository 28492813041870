import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title={"서점극장 라블레"} titleTemplate={`%s`} />
    <h1>서점극장 라블레</h1>
    <p>
      서점극장 라블레는
      <br />
      2021년 4월 1일 문을 연 세계문학 서점입니다.
      <br />
      낮에는 서점으로, 밤에는 비밀극장으로 변신합니다.
    </p>
    <br />
    <p>
      운영시간 :
      <br />
      화-목 9-15시
      <br />
      금 13-22시
      <br />
      토 13-18시
    </p>
    <p>
      오시는 길 : 서울시 마포구 백범로 25길 5, 1층
      <br />
      전화 :{" "}
      <a
        style={{ color: "black", textDecorationLine: "none" }}
        href="tel:+82-2-6368-1578"
      >
        02-6368-1578
      </a>
      <br />
      이메일 :{" "}
      <a
        style={{ color: "black", textDecorationLine: "none" }}
        href="mailto:mail@rabelais.kr"
      >
        mail@rabelais.kr
      </a>
      <br />
      인스타그램 :{" "}
      <a
        style={{ color: "gray", textDecorationLine: "none" }}
        href="https://www.instagram.com/rabelais.kr"
      >
        @rabelais.kr
      </a>
    </p>
    <br />
    <br />
    <p style={{ textAlign: `center` }}>
      <a href="https://linktr.ee/rabelais.kr" style={{ color: `white`, padding: `1em`, backgroundColor: `black`, textDecorationLine: `none` }}>행사 신청</a>
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
  </Layout>
)

export default IndexPage
